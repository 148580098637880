import { Component, OnInit } from '@angular/core';
import { AuthenticationService, BaseComponent, CredentialsService } from '@core';
import { Router } from '@angular/router';
import { AuthenticationUser, AuthorizationEntity } from '@app/@core/authentication/authentication.models';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface UserInfo extends AuthenticationUser {
  avatar: any;
  info: string;
}

@Component({
  selector: 'sw-header-user-account',
  templateUrl: './header-user-account.component.html',
  styleUrls: ['./header-user-account.component.scss'],
})
export class HeaderUserAccountComponent extends BaseComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private router: Router
  ) {
    super();
  }

  public icons = {
    faUser,
  };

  public get user(): UserInfo {
    const credentials = this.credentialsService.credentials ?? ({} as AuthorizationEntity);
    return {
      ...credentials?.user,
      avatar: {
        picture: null,
        name: credentials?.user?.name,
        status: 'online',
      },
      info: credentials?.user?.email,
    };
  }

  ngOnInit() {}

  logout() {
    this.authenticationService.logout();
  }
}
