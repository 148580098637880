import { RedirectService } from '@core/services/redirect.service';
import { finalize } from 'rxjs/operators';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, AbstractControl, AbstractControlOptions } from '@angular/forms';
import { TranslatedValidatorService } from '@shared/services/translated-validators.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthenticationService, BaseFormComponent } from '@core';
import Swal from 'sweetalert2';

@Component({
  selector: 'sw-auth-register',
  templateUrl: './auth-register.component.html',
  styleUrls: ['./auth-register.component.scss']
})
export class AuthRegisterComponent extends BaseFormComponent implements OnInit {
  icons = {
    faLongArrowAltRight,
  };

  constructor(
    el: ElementRef,
    toastr: ToastrService,
    translate: TranslateService,

    private api: AuthenticationService,
    private tv: TranslatedValidatorService,
    private fb: FormBuilder,
    private redirectService: RedirectService,
  ) {
    super(el, toastr, translate);
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.buildForm();
  }


  onSubmit() {
    const { valid, value } = this.form;
    if (!this.beforeSubmit()) return;

    this.api
      .register(value)
      .pipe(finalize(this.finalizeSubmit.bind(this)))
      .subscribe((_) => {
        Swal.fire({
          title: this.translate.instant('AUTH.REGISTER_SUCCESS.TITLE'),
          text: this.translate.instant('AUTH.REGISTER_SUCCESS.TEXT')
        })
      });
  }

  private buildForm(){
    this.form = this.fb.group({
      name: [null],
      email: [null, this.tv.required()],
      identifier: [null, this.tv.required()]
    });
  }
}
