import { Component, Input } from '@angular/core';
import { faCalendar, faFileDownload, faSave } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Announcement } from '@core/api-models/announcements/announcement.model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'sw-announcement-modal',
  templateUrl: './announcement.modal.component.html',
})
export class AnnouncementModalComponent {
  isLoading: boolean = false;
  icons = {
    faSave,
    faCalendar,
    faFileDownload,
  };

  @Input() announcement: Announcement = null;
  @Input() modal: NgbModalRef;

  public ColumnMode = ColumnMode;

  constructor() {}

  downloadFile(file: any) {
    const byteCharacters = atob(file.base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    FileSaver.saveAs(url, file.file_name);
  }

  dismissModal() {
    this.modal.dismiss();
  }
}
