<div class="nav-item account dropdown" dropdown placement="bottom right">
  <div class="d-flex align-items-end">
    <span class="mr-4 align-text-bottom text-white">
      {{ 'ACCOUNT.CUSTOMER_CODE' | translate }}:
      <b>{{ user?.customer?.id }}</b>
    </span>
    <span class="mr-2 text-center text-white">
      <b>{{ user?.name }}</b>
      <br />
      {{ user?.customer?.name?.length > 40 ? (user?.customer?.name | slice : 0 : 40) + '...' : user?.customer?.name }}
    </span>
    <sw-nav-link class="dropdown-toggle" dropdownToggle [dark]="dark">
      <sw-avatar-info>
        <sw-avatar [avatar]="user.avatar" class="user-picture"></sw-avatar>
      </sw-avatar-info>
    </sw-nav-link>
  </div>

  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
    <div class="dropdown-heading rounded-top bg-3 arrow position-top">
      <div class="media align-items-center">
        <sw-avatar-info [name]="user.avatar.name" [info]="user.info">
          <sw-avatar [avatar]="user.avatar" class="user-picture"></sw-avatar>
        </sw-avatar-info>
      </div>
    </div>

    <ul class="dropdown-body list list-group list-group-flush">
      <li class="list-group-item list-group-item-action" [routerLink]="['/account']">
        <a>
          <sw-icon [icon]="icons.faUser"></sw-icon>
          {{ 'ACCOUNT.TITLE' | translate }}
        </a>
      </li>
      <!--<li class="list-group-item list-group-item-action">
        <a href="javascript:;">
          <i-feather name="settings" class="mr-2"></i-feather>
          Settings
        </a>
      </li>-->
    </ul>

    <div class="dropdown-footer d-flex justify-content-between align-items-center">
      <button class="btn btn-rounded btn-primary" (click)="logout()">{{ 'AUTH.LOGOUT' | translate }}</button>
    </div>
  </div>
</div>
