<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-md-6 col-lg-7 fullscreen-md d-flex justify-content-center align-items-center blanket blanket-primary alpha-8 image-background cover" style="background-image: url(assets/img/autostrade.jpeg)">
            <div class="content">
                <img src="assets/img/logo.svg" class="img-responsive mb-4 mb-md-6" alt="Portale Clienti" />

                <h2 class="display-4 display-lg-3 text-contrast mt-4 mt-md-0">
                    {{ 'WELCOME_IN' | translate }} <span class="bold d-block" translate>APP_NAME</span>
                </h2>
                <div class="d-flex flex-column mt-md-6 w-25"></div>
            </div>
        </div>

        <div class="col-md-5 col-lg-4 mx-auto">
            <div class="login-form mt-md-0">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
