import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { CredentialsService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class PermissionService implements CanLoad {
  // NOT READY
  constructor(private _router: Router, private credentialsService: CredentialsService) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (typeof route.data === 'undefined' || typeof route.data.permission === 'undefined') {
      return false;
    }
    const can = this.credentialsService.can(route.data.permission);
    if (!can) {
      this._router.navigate(['/home']).then(() => {});
    }
    return can;
  }
}
