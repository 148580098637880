import { Component, OnInit, HostBinding, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { AuthenticationService, Logger } from '@core';
import { Router } from '@angular/router';
import { faInbox, faLock, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { NavigationService } from '@app/layout/services/navigation.service';
import { NavigationOptions } from '@app/layout/models/navigation';

const logger = new Logger('SidenavComponent');

@Component({
  selector: 'sw-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  // tslint:disable-next-line
  host: { class: 'sidenav' },
})
export class SidenavComponent implements OnInit {
  @HostBinding('class.fixed')
  @Input()
  fixed: boolean;

  @HostBinding('class.hover')
  hover: boolean;

  icons = {
    faInbox,
    faLock,
    faSignOutAlt,
  };

  navGroups: NavigationOptions[];

  @Output()
  sideNavToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.collapsed')
  @Input()
  collapsed: boolean;

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private authenticationService: AuthenticationService
  ) {}

  public get user(): any {
    return {
      avatar: {
        picture: null,
        name: this.authenticationService.credentialsService.credentials?.user?.name,
        status: 'online',
      },
      info: this.authenticationService.credentialsService.credentials?.user?.email,
    };
  }

  @HostListener('mouseenter')
  onMouseOver() {
    this.hover = true;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hover = false;
  }

  ngOnInit() {
    this.navigation.getNavigationItems().subscribe((groups) => (this.navGroups = groups));
  }

  toggleSidenav() {
    this.collapsed = !this.collapsed;
    this.sideNavToggled.emit(this.collapsed);
  }

  logout() {
    this.authenticationService.logout();
  }

  onNavLinkToggle(isOpen: boolean) {
    logger.debug(`Nav link toggled ${isOpen}`);
  }
}
