import { AuthRegisterComponent } from './components/auth-register/auth-register.component';
import { AuthResetComponent } from './components/auth-reset/auth-reset.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthShellComponent } from './components/auth-shell/auth-shell.component';
import { AuthLoginComponent } from './components/auth-login/auth-login.component';
import { extract } from '@app/i18n';
import { AuthForgotComponent } from './components/auth-forgot/auth-forgot.component';

const routes: Routes = [
  {
    path: '',
    component: AuthShellComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'login', component: AuthLoginComponent, data: { title: extract('AUTH.LOGIN') } },
      { path: 'reset/:token', component: AuthResetComponent, data: { title: extract('AUTH.RESET_PASSWORD') } },
      { path: 'forgot', component: AuthForgotComponent, data: { title: extract('AUTH.RECOVER_PASSWORD') } },
      { path: 'register', component: AuthRegisterComponent, data: { title: extract('AUTH.REGISTER') } },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
  static declarations = [AuthLoginComponent, AuthShellComponent, AuthForgotComponent, AuthResetComponent];
}
