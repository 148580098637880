<input class="form-control" [name]="name" type="radio" [id]="currentId" (change)="toggle($event)" [checked]="checked" />
<label class="mb-0" [for]="currentId">
  <sw-card class="bw-2 card-option">
    <sw-card-body>
      <span class="check-mark rounded-circle center-flex text-contrast" *ngIf="showMark && checked">
        <sw-icon [icon]="check"></sw-icon>
      </span>

      <ng-content></ng-content>
    </sw-card-body>
  </sw-card>
</label>