<div class="container-fluid">
  <nav class="navbar navbar-expand-md">
    <div class="sidenav-logo">
      <a class="sidenav-brand" href="/home">
        <img src="assets/img/logo.svg" alt="Portale Clienti" class="logo" />
      </a>

      <sw-menu-toggler class="sidenav-toggler" (click)="toggleSidenav()"></sw-menu-toggler>
    </div>

    <div class="navbar-collapse">
      <sw-header-navbar-item
        class="nav-header-item"
        *ngFor="let option of navGroups"
        [option]="option"
        (onToggle)="option.open = $event"
        [level]="1"
      >
        <ng-container dropdown-icon>
          <sw-dropdown-icon class="dd-icon d-none d-md-block"></sw-dropdown-icon>
          <sw-dropdown-icon class="dd-icon d-block d-md-none" [open]="option.open"></sw-dropdown-icon>
        </ng-container>
      </sw-header-navbar-item>
    </div>
  </nav>
</div>
