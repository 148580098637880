import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ElementRef } from '@angular/core';
import { BaseFormComponent } from '@core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'sw-quick-sidenav-settings',
  templateUrl: './quick-sidenav-settings.component.html',
  styleUrls: ['./quick-sidenav-settings.component.scss'],
})
export class QuickSidenavSettingsComponent extends BaseFormComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    public el: ElementRef,
    public toastr: ToastrService,
    public translate: TranslateService
  ) {
    super(el, toastr, translate);
    this.createForm();
  }

  ngOnInit() {}

  private createForm() {
    this.form = this.fb.group({
      dailySummaryEmails: [true],
      automaticResponse: [false],
      otherCanViewMyStatus: [false],
      allowRemoteAccess: [false],
      automaticSigning: [true],
      clearCacheOnLogoff: [true],
    });
  }
}
