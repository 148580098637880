import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ModelMapper } from '../mapper/model-mapper';
import { environment } from '@env/environment';

import { Logger } from '../logger.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CredentialsService } from '../authentication/credentials.service';
import { Router } from '@angular/router';

const logger = new Logger('ApiService');

export class RequestResponseMessage {
  type: 'info' | 'warning' | 'error' | 'success';
  text: string;
}

export class RequestResponse {
  success: boolean;
  messages?: RequestResponseMessage[];

  data?: any;

  logout?: boolean;
  redirect?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
    private credentialsService: CredentialsService,
    private router: Router,
  ) {}

  public get<T>(route: string): Observable<T> {
    logger.debug('GET', route);
    return this.handle(this.http.get<RequestResponse>(environment.serverUrl + '/' + route));
  }

  public post<T>(route: string, data: object): Observable<T> {
    logger.debug('POST', route, data);
    return this.handle(this.http.post<RequestResponse>(environment.serverUrl + '/' + route, data));
  }

  public delete<T>(route: string): Observable<T> {
    logger.debug('DELETE', route);
    return this.handle(this.http.delete<RequestResponse>(environment.serverUrl + '/' + route));
  }

  public put<T>(route: string, data: object): Observable<T> {
    logger.debug('PUT', route, data);
    return this.handle(this.http.put<RequestResponse>(environment.serverUrl + '/' + route, data));
  }

  public checkResponse(data: RequestResponse) {
    if (data.logout === true) {
      if(this.credentialsService.isAuthenticated()){
        const state = this.router.routerState.snapshot;
        this.credentialsService.clearCredentials();
        this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
      }
    } else if (data.redirect) {
      if (typeof data.redirect === 'string') this.router.navigate([data.redirect]);
    }

    if (data.messages) {
      this.showMessages(data.messages);
    }
  }

  public handle<T>(request: Observable<RequestResponse>): Observable<T> {
    return request.pipe(
      map((data) => {
        logger.debug('RESPONSE:', data, request);
        if (data) {
          if(!data.success) throw data;
          this.checkResponse(data);

          return typeof data.data !== 'undefined' ? data.data : data;
        }

        throw { error: 'ERRORS.REQUEST_FAILED_UNKNOWN_REASON' };
      }),

      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          logger.debug('http error...');
          error = error.error;
        } else {
          logger.debug('some other error...');
        }

        logger.debug('errore', { e: error });
        this.checkResponse(error);
        /*if(error?.messages){
          const messages: RequestResponseMessage[] = error.messages;
          if(messages.length){
            this.showMessages(messages);
            throw error;
          }
        }*/

        if (!error.messages) {
          if (typeof error === 'object' && typeof error.message !== 'undefined' && typeof error.error === 'undefined') {
            error.error = error.message;
          }
          if (typeof error === 'object' && error.exception) {
            error = error.exception;
          }

          let msg = this.recursiveGetErrors(error);
          if (!msg) msg = 'ERRORS.REQUEST_FAILED_UNKNOWN_REASON';
          this.toastr.error(this.translate.instant(msg), this.translate.instant('ERRORS.REQUEST_FAILED'), {
            closeButton: true,
            timeOut: 10000,
            progressBar: true,
          });
        }

        throw error;
      })
    );
  }

  private showMessages(messages: RequestResponseMessage[]) {
    messages.forEach((message) => {
      this.showMessage(message);
    });
  }

  private showMessage(message: RequestResponseMessage) {
    let messageFunction;
    const title = this.translate.instant('ERRORS.' + message.type.toUpperCase());

    if (message.type === 'error') {
      messageFunction = this.toastr.error.bind(this.toastr);
    } else if (message.type === 'warning') {
      messageFunction = this.toastr.warning.bind(this.toastr);
    } else if (message.type === 'info') {
      messageFunction = this.toastr.info.bind(this.toastr);
    } else if (message.type === 'success') {
      messageFunction = this.toastr.success.bind(this.toastr);
    } else {
      logger.debug('Message type not found ', message);
      messageFunction = this.toastr.show.bind(this.toastr);
    }

    messageFunction(this.translate.instant(message.text), title, {
      closeButton: true,
      timeOut: 10000,
      progressBar: true,
    });
  }

  private recursiveGetErrors(error: { error: any } | string | undefined): string {
    if (typeof error === 'string') {
      return error;
    } else if (typeof error === 'undefined') {
      return 'ERRORS.REQUEST_FAILED_UNKNOWN_REASON';
    } else {
      return this.recursiveGetErrors(error.error);
    }
  }
}
