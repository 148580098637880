<button
  [type]="type"
  class="btn btn-{{ outlined ? 'outline-' : '' }}{{ color ? color : 'primary' }} {{shaking ? 'shake' : ''}}"
  [disabled]="disabled || isLoading"
  [title]="title"
>
  <span #ref><ng-content></ng-content></span>
  <fa-icon
    [icon]="isLoading ? loadingIcon : icon"
    [spin]="isLoading"
    [ngClass]="{ 'ml-2': ref.nativeElement?.childNodes.length == 0 }"
    *ngIf="icon"
  ></fa-icon>
</button>
