import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControlOptions } from '@angular/forms';
import { Logger, AuthenticationService, BaseFormComponent } from '@core';
import { ActivatedRoute, Params } from '@angular/router';

import { faCheck, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faUser, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { finalize } from 'rxjs/operators';
import { RedirectService } from '@core/services/redirect.service';

const log = new Logger('Forgot');

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-reset.component.html',
  styleUrls: ['./auth-reset.component.scss'],
})
export class AuthResetComponent extends BaseFormComponent implements OnInit {
  public icons = {
    faLongArrowAltRight,
    faUser,
    faArrowAltCircleRight,
    faCheck,
  };

  private token: string;

  constructor(
    private _redirect: RedirectService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    public el: ElementRef,
    public toastr: ToastrService,
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {
    super(el, toastr, translate);
    this.token = this.route.snapshot.params.token;

    if (authenticationService.isAuthenticated) {
      _redirect.toHome();
    }

    this.isLoading = false;
    this.createForm();
  }

  ngOnInit() {}

  onSubmit() {
    const { valid, value } = this.form;
    if (!this.beforeSubmit()) return;

    this.authenticationService
      .reset(value)
      .pipe(finalize(this.finalizeSubmit.bind(this)))
      .subscribe(_ => {
      });
  }

  redirect(params: Params) {
    if (params.redirect) {
      this._redirect.to(params.redirect, { replaceUrl: true });
    } else {
      this._redirect.toHome();
    }
  }

  private createForm() {
    this.form = this.formBuilder.group(
      {
        token: [this.token],
        password: ['', [Validators.required, Validators.minLength(5)]],
        password_confirmation: [''],
      },
      { validators: this.checkPassword.bind(this) } as AbstractControlOptions
    );
  }

  private checkPassword(form: FormGroup) {
    const password = form.value.password;
    const confirmPassword = form.value.password_confirmation;

    return password === confirmPassword ? null : { password_not_same: true };
  }
}
