import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '@core';
import { faClock, faEllipsisH, faPencilAlt, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'sw-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent extends BaseComponent implements OnInit, AfterViewInit {
  iconHidden: boolean;

  @ViewChild('iconView', { static: false })
  iconView: ElementRef;

  public icons = {
    default: faEllipsisH,
    clock: faClock,
  };

  @Input() icon: string = 'default';
  @Input() color: string = null;

  @Input()
  appendToBody: boolean = false;

  constructor(private cdRef: ChangeDetectorRef) {
    super('context-menu');
  }

  getIcon(icon: string) {
    if (typeof this.icons[icon] !== 'undefined') {
      return this.icons[icon];
    }
    return this.icons.default;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.iconHidden = this.iconView.nativeElement.children.length === 0;

    this.cdRef.detectChanges();
  }
}
