import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class TranslatedValidatorService {
  constructor(private translate: TranslateService) {}

  /**
   * function that takes a validator function (e.g. Validators.required),
   * a message (string) and an optional formatting function, applies
   * the validator, translates the message and applies the format
   *
   * exposes the values translated_error in the object, and translation in every error
   */
  public translatedValidator(
    validator: (f: any) => any,
    message: string,
    format?: (str: string, error: any) => string
  ) {
    message = this.translate.instant(message);

    return (fc: AbstractControl) => {
      let translatedErrors = {};
      const errors = validator(fc);
      if (!errors) return null;
      for (const [key, value] of Object.entries(errors)) {
        let valueWithTranslation;
        let translation = message;
        if (format) translation = format(translation, value);

        if (typeof value === 'object') valueWithTranslation = { ...value, translation };
        else valueWithTranslation = { value, translation };
        translatedErrors[key] = valueWithTranslation;
        translatedErrors = { ...translatedErrors, translated_error: translation };
      }
      return translatedErrors;
    };
  }

  public required(message: string = 'FORMS.VALIDATION.REQUIRED'){
    return this.translatedValidator(Validators.required, message);
  }

  public requiredTrue(message: string = 'FORMS.VALIDATION.REQUIRED_TRUE'){
    return this.translatedValidator(Validators.requiredTrue, message);
  }

  public minLength(minLength: number, message: string = 'FORMS.VALIDATION.MIN_LENGTH'){
    return this.translatedValidator(Validators.minLength(minLength), message,
      (str, error) => str.
        replace('%requiredLength', error.requiredLength).
        replace('%actualLength', error.actualLength)
    );
  }

  public maxLength(maxLength: number, message: string = 'FORMS.VALIDATION.MAX_LENGTH'){
    return this.translatedValidator(Validators.maxLength(maxLength), message,
      (str, error) => str.
        replace('%requiredLength', error.requiredLength).
        replace('%actualLength', error.actualLength)
    );
  }

  public email(message: string = 'FORMS.VALIDATION.EMAIL'){
    return this.translatedValidator(Validators.email, message);
  }

  public min(min: number, message: string = 'FORMS.VALIDATION.MIN'){
    return this.translatedValidator(Validators.min(min), message,
      (str, error) => str.
        replace('%min', error.min).
        replace('%actual', error.actual)
    );
  }

  public max(max: number, message: string = 'FORMS.VALIDATION.MAX'){
    return this.translatedValidator(Validators.max(max), message,
      (str, error) => str.
        replace('%max', error.min).
        replace('%actual', error.actual)
    );
  }

  public pattern(pattern: string, message: string = 'FORMS.VALIDATION.PATTERN'){
    return this.translatedValidator(Validators.pattern(pattern), message,
      (str, error) => str.
        replace('%requiredPattern', error.requiredPattern).
        replace('%actualValue', error.actualValue)
    );
  }
}
