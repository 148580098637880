import { TranslatedValidatorService } from '@shared/services/translated-validators.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Logger, AuthenticationService, BaseFormComponent } from '@core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { faCheck, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faUser, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { finalize } from 'rxjs/operators';
import { RedirectService } from '@core/services/redirect.service';

const log = new Logger('Forgot');

@Component({
  selector: 'app-auth-forgot',
  templateUrl: './auth-forgot.component.html',
  styleUrls: ['./auth-forgot.component.scss'],
})
export class AuthForgotComponent extends BaseFormComponent implements OnInit {
  public icons = {
    faLongArrowAltRight,
    faUser,
    faArrowAltCircleRight,
    faCheck,
  };

  constructor(
    private _redirect: RedirectService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    public el: ElementRef,
    public toastr: ToastrService,
    public translate: TranslateService,
    private tv: TranslatedValidatorService,
  ) {
    super(el, toastr, translate);

    if (authenticationService.isAuthenticated) {
      _redirect.toHome();
    }

    this.isLoading = false;
    this.createForm();
  }

  ngOnInit() {}

  onSubmit() {
    const { valid, value } = this.form;
    if (!this.beforeSubmit()) return;

    this.authenticationService
      .forgot(value)
      .pipe(finalize(this.finalizeSubmit.bind(this)))
      .subscribe((data) => {});
  }

  redirect(params: Params) {
    if (params.redirect) {
      this._redirect.to(params.redirect, { replaceUrl: true });
    } else {
      this._redirect.toHome();
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: [null, this.tv.required()],
      customer_code: [null, this.tv.required()],
    });
  }
}
