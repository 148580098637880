<nav class="navbar" [ngClass]="{ 'navbar-dark bg-dark': dark }">
  <div class="container-fluid">
    <a class="toolbar-brand" href="/home" *ngIf="branded">
      <img src="assets/img/logo.svg" alt="Portale Clienti" class="logo" *ngIf="!dark" />
      <img src="assets/img/logo.svg" alt="Portale Clienti" class="logo" *ngIf="dark" />
    </a>

    <nav class="navbar-nav nav ml-md-auto">
      <sw-menu-toggler
        [dark]="dark"
        class="sidenav-toggler d-md-none mr-auto"
        (click)="toggleSidenav()"
      ></sw-menu-toggler>

      <!-- ACCOUNT DROPDOWN -->
      <sw-header-user-account [dark]="dark" class="mr-3"></sw-header-user-account>
    </nav>
  </div>
</nav>
