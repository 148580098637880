import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface MenuIndex {
  menuIndex: number;
  submenuIndex: number;
}

export interface MenuItem {
  icon: string;
  text: string;
  value: string;
  subMenu: Array<{
    text: string;
    value: string;
  }>;
}
@Component({
  selector: 'sw-context-submenu',
  templateUrl: './context-submenu.component.html',
  styleUrls: ['./context-submenu.component.scss'],
})
export class ContextSubmenuComponent {
  @Input() public menuItems: Array<{
    service: AppService,
    files: Array<string>
  }> = [];
  @Output() public itemSelected = new EventEmitter<MenuIndex>();

  public onClick(event: MouseEvent, menuIndex: number, submenuIndex: number) {
    event.stopPropagation();
    this.itemSelected.emit({
      menuIndex,
      submenuIndex
    });
  }
}
