<ng-content></ng-content>
<ng-template #cgcModal>
  <div class="modal-header">
    <h2>Aggiornamento Condizioni Generali di Contratto</h2>
  </div>
  <div class="modal-body">
    <p style="font-size: 20px">
      Ho letto e accetto le nuove
      <a type="button" style="color: blue" (click)="downloadCgc()" target="_blank">Condizioni Generali di Contratto</a>,
      valevoli per tutti i servizi erogati da {{cgcDocument.agency.name}} in favore del Cliente, inclusi quelli precedentemente
      attivati ed oggetto di precedenti offerte e/o appendici
    </p>
  </div>
  <div class="modal-footer">
    <sw-button [isLoading]="submittedCgc" [color]="'danger'" (click)="submitCgc(false)"> NON ACCETTO </sw-button>
    <sw-button [isLoading]="submittedCgc" [color]="'info'" (click)="submitCgc(true)"> ACCETTO </sw-button>
  </div>
</ng-template>
