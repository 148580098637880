import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';

import { CredentialsService } from './credentials.service';
import { LoginContext, RegisterContext, AuthorizationEntity, AuthenticationUser } from './authentication.models';

import { ApiService } from '@core/api/api.service';
import { interval } from 'rxjs';
import { Router } from '@angular/router';

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  private authLoop: Subscription;

  get isAuthenticated() {
    return this.credentialsService.isAuthenticated();
  }

  constructor(public credentialsService: CredentialsService, private api: ApiService, private router: Router) {
    this.authLoop = interval(1 * 60 * 1000).subscribe((_: any) => {
      if (this.isAuthenticated) {
        this.refreshAuth();
      }
    });
  }
  ngOnDestroy(): void {
    if (this.authLoop) this.authLoop.unsubscribe();
  }

  login(data: LoginContext): Observable<AuthorizationEntity> {
    const call = this.api
      .post<AuthorizationEntity>('auth/login', data)
      .pipe(share());

    call.subscribe((credentials) => this.credentialsService.setCredentials(credentials));

    return call;
  }

  register(data: any): Observable<any> {
    return this.api.post('auth/register', data);
  }

  logout() {
    this.credentialsService.clearCredentials();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  forgot(data: any): Observable<AuthorizationEntity> {
    return this.api.post<AuthorizationEntity>('auth/forgot', data);
  }

  reset(data: any): Observable<AuthorizationEntity> {
    return this.api.post<AuthorizationEntity>('auth/reset', data);
  }

  refreshAuth() {
    this.api
      .get<AuthorizationEntity>('auth/me')
      .subscribe((credentials: AuthorizationEntity) => this.credentialsService.setCredentials(credentials));
  }
}
