import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { PermissionService } from '@core/middlewares/permission.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'home',
      loadChildren: () => import('./content/home/home.module').then((m) => m.HomeModule),
      canActivate: [],
    },
    {
      path: 'transits',
      loadChildren: () => import('./content/transits/transits.module').then((m) => m.TransitsModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.transiti' },
    },
    {
      path: 'vehicles',
      loadChildren: () => import('./content/vehicles/vehicles.module').then((m) => m.VehiclesModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.anagrafiche' },
    },
    {
      path: 'contacts',
      loadChildren: () => import('./content/contacts/contacts.module').then((m) => m.ContactsModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.anagrafiche' },
    },
    {
      path: 'devices',
      loadChildren: () => import('./content/devices/devices.module').then((m) => m.DevicesModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.anagrafiche' },
    },
    {
      path: 'account',
      loadChildren: () => import('./content/account/account.module').then((m) => m.AccountModule),
    },
    {
      path: 'invoices',
      loadChildren: () => import('./content/invoices/invoices.module').then((m) => m.InvoicesModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.invoices' },
    },
    {
      path: 'telepass_invoices',
      loadChildren: () =>
        import('./content/telepass-invoices/telepass-invoices.module').then((m) => m.TelepassInvoicesModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.invoices' },
    },
    {
      path: 'refunds',
      loadChildren: () =>
        import('./content/refund-requests/refund-requests.module').then((m) => m.RefundRequestsModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.rimborsi' },
    },
    {
      path: 'documents',
      loadChildren: () => import('./content/documents/documents.module').then((m) => m.DocumentsModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.documents' },
    },
    {
      path: 'useful-links',
      loadChildren: () => import('./content/useful-links/useful-links.module').then((m) => m.UsefulLinksModule),
    },
    {
      path: 'planning',
      loadChildren: () => import('./content/planning/planning.module').then((m) => m.PlanningModule),
    },
    {
      path: 'login-credentials',
      loadChildren: () =>
        import('./content/login-credentials/login-credentials.module').then((m) => m.LoginCredentialsModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.credentials' },
    },
    {
      path: 'trafori',
      loadChildren: () => import('./content/trafori/trafori.module').then((m) => m.TraforiModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.trafori' },
    },
    {
      path: 'utenti',
      loadChildren: () => import('./content/users/users.module').then((m) => m.UsersModule),
      canLoad: [PermissionService],
      data: { permission: 'user_customers.permissions_management' },
    },
    {
      path: 'announcements',
      loadChildren: () => import('./content/announcements/announcements.module').then((m) => m.AnnouncementsModule),
    },
  ]),

  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
