<div class="menu-dropdown" dropdown placement="bottom right" [container]="appendToBody ? 'body' : ''">
  <sw-nav-link class="dropdown-toggle" dropdownToggle>
    <div #iconView *ngIf="!iconHidden; else withIcon">
      <ng-content select="[menu-icon]"></ng-content>
    </div>

    <ng-template #withIcon>
      <sw-icon [style.color]="color" [icon]="getIcon(icon)"></sw-icon>
    </ng-template>
  </sw-nav-link>

  <div *dropdownMenu class="dropdown-menu dropdown-menu-right py-2">
    <ng-content></ng-content>
  </div>
</div>
