import { Injectable } from '@angular/core';
import { ApiService } from '@app/@core';
import { Observable } from 'rxjs';
import { Announcement } from '@core/api-models/announcements/announcement.model';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementCallsService {
  constructor(private api: ApiService) {}

  getLast(): Observable<Announcement[]> {
    return this.api.get('announcements/list/last');
  }

  getAnnouncementsTable(data: any): Observable<Announcement[]> {
    return this.api.post('announcements/list', { ...data });
  }
}
