<form (ngSubmit)="onSubmit()" [formGroup]="form" class="cozy" novalidate>
    <div class="form-group">
        <label class="control-label bold small text-uppercase">{{ 'AUTH.CUSTOMER_CODE' | translate }}</label>
        <input formControlName="customer_code" class="form-control display-errors bg-light form-control-rounded" [placeholder]="'AUTH.CUSTOMER_CODE_PLACEHOLDER' | translate" />
        <small *ngIf="f.customer_code.errors && f.customer_code.touched" class="text-danger">
            {{ f.customer_code.errors.translated_error }}
        </small>
    </div>
    
    <div class="form-group">
        <label class="control-label bold small text-uppercase">{{ 'AUTH.USERNAME' | translate }}</label>
        <input name="email" type="email" formControlName="email" class="form-control display-errors bg-light form-control-rounded" [placeholder]="'AUTH.USERNAME' | translate" />
        <small [hidden]="form.controls.email.valid || form.controls.email.untouched" class="text-danger">
            {{ 'AUTH.USERNAME' | translate }} {{ 'IS_REQUIRED' | translate }}
        </small>
    </div>

    <div class="form-group">
        <label class="control-label bold small text-uppercase">{{ 'AUTH.PASSWORD' | translate }}</label>
        <input type="password" formControlName="password" class="form-control display-errors bg-light form-control-rounded" [placeholder]="'AUTH.PASSWORD' | translate" />
        <small [hidden]="form.controls.password.valid || form.controls.password.untouched" class="text-danger">
            {{ 'AUTH.PASSWORD' | translate }} {{ 'IS_REQUIRED' | translate }}
        </small>
    </div>

    <div class="form-group">
        <sw-checkbox formControlName="remember" label="{{ 'AUTH.REMEMBER_ME' | translate }}" [id]="'rememberme'"></sw-checkbox>
    </div>

    <div id="forgotPassword" class="form-group mb-0 d-flex align-items-center justify-content-between">
        <a routerLink="/forgot" class="text-warning small">{{ 'AUTH.FORGOT' | translate }}</a>

        <sw-button type="submit" color="primary" [icon]="icons.faLongArrowAltRight" [isLoading]="isLoading">
            <span>{{ 'AUTH.LOGIN' | translate }}</span>
        </sw-button>
    </div>
</form>

<div class="register">
    <div class="mt-2" color="secondary">
        {{ 'AUTH.DONT_HAVE_AN_ACCOUNT' | translate }}
    </div>
    <div>
        <sw-button type="button"  [color]="warning" [routerLink]="['../', 'register']" href="javascript:void()">
            <span>{{ 'AUTH.REGISTER_NOW' | translate }} </span>
        </sw-button>
        
    </div>
</div>
