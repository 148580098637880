<address>
  <div class="address-element" *ngIf="!addressHidden">
    <sw-icon [icon]="icons.location" class="icon"></sw-icon>
    <div #addressView>
      <ng-content></ng-content>
    </div>
  </div>

  <div class="address-element" *ngIf="url">
    <sw-icon [icon]="icons.link" class="icon"></sw-icon>
    {{ url }}
  </div>

  <div class="address-element" *ngIf="email">
    <sw-icon [icon]="icons.mail" class="icon"></sw-icon>
    {{ email }}
  </div>

  <div class="address-element" *ngIf="phone">
    <sw-icon [icon]="icons.phone" class="icon"></sw-icon>
    {{ phone }}
  </div>
</address>