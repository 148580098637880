<form (ngSubmit)="onSubmit()" [formGroup]="form" class="cozy" novalidate>
  <div class="form-group">
      <label class="control-label bold small text-uppercase">{{ 'AUTH.NAME' | translate }}</label>
      <input name="name" type="text" formControlName="name" class="form-control display-errors bg-light form-control-rounded" [placeholder]="'AUTH.NAME' | translate" />
  </div>

  <div class="form-group">
    <label class="control-label bold small text-uppercase">{{ 'AUTH.USERNAME' | translate }}</label>
    <input name="email" type="email" formControlName="email" class="form-control display-errors bg-light form-control-rounded" [placeholder]="'AUTH.USERNAME' | translate" />
    <small [hidden]="f.email.valid || f.email.untouched" class="text-danger">
      {{ 'AUTH.USERNAME' | translate }} {{ 'IS_REQUIRED' | translate }}
    </small>
</div>

  <div class="form-group">
      <label class="control-label bold small text-uppercase">{{ 'AUTH.IDENTIFIER' | translate }}</label>
      <input formControlName="identifier" class="form-control display-errors bg-light form-control-rounded" [placeholder]="'AUTH.IDENTIFIER_PLACEHOLDER' | translate" />
      <small *ngIf="f.identifier.errors && f.identifier.touched" class="text-danger">
        {{ f.identifier.errors.translated_error }}
      </small>
  </div>

  <div class="form-group mb-0 d-flex align-items-center justify-content-between">
      <a routerLink="/login" class="text-warning small">{{ 'AUTH.LOGIN' | translate }}</a>

      <sw-button type="submit" color="primary" [icon]="icons.faLongArrowAltRight" [isLoading]="isLoading">
          <span>{{ 'AUTH.CREATE_ACCOUNT' | translate }}</span>
      </sw-button>
  </div>
</form>