<div class="side-wrapper">
    <tabset [justified]="true" type="tabs tabs-clean slide-left padded">
        <tab>
            <ng-template tabHeading>
                <sw-icon icon="list" class="icon"></sw-icon>
            </ng-template>
            <sw-quick-sidenav-tasks></sw-quick-sidenav-tasks>
        </tab>
        <tab>
            <ng-template tabHeading>
                <sw-icon icon="users" class="icon"></sw-icon>
            </ng-template>
            <!--<sw-quick-sidenav-contacts></sw-quick-sidenav-contacts>-->
        </tab>
        <tab>
            <ng-template tabHeading>
                <sw-icon icon="settings" class="icon"></sw-icon>
            </ng-template>
            <sw-quick-sidenav-settings></sw-quick-sidenav-settings>
        </tab>
    </tabset>
</div>
