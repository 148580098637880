import { TranslatedValidatorService } from '@shared/services/translated-validators.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Logger, AuthenticationService, BaseFormComponent } from '@core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { faCheck, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faUser, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { finalize } from 'rxjs/operators';
import { RedirectService } from '@core/services/redirect.service';

const log = new Logger('Login');

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent extends BaseFormComponent implements OnInit {
  public icons = {
    faLongArrowAltRight,
    faUser,
    faArrowAltCircleRight,
    faCheck,
  };
  public warning = "warning";
  constructor(
    private _redirect: RedirectService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    public el: ElementRef,
    public toastr: ToastrService,
    public translate: TranslateService,
    private tv: TranslatedValidatorService,
  ) {
    super(el, toastr, translate);

    if (authenticationService.isAuthenticated) {
      _redirect.toHome();
    }

    this.isLoading = false;
    this.createForm();
  }

  ngOnInit() {}

  /**
   * Logs-in the user
   * @param form The form value: user + password
   */
  onSubmit() {
    const { valid, value } = this.form;
    if (!this.beforeSubmit()) return;

    this.authenticationService
      .login(value)
      .pipe(finalize(this.finalizeSubmit.bind(this)))
      .subscribe((_) => this.route.queryParams.subscribe((params) => this.redirect(params)));
  }

  redirect(params: Params) {
    if (params.redirect) {
      this._redirect.to(params.redirect, { replaceUrl: true });
    } else {
      this._redirect.toHome();
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: [null, this.tv.required()],
      customer_code: [null, this.tv.required()],
      password: [null, this.tv.required()],
      remember: false,
    });
  }
}
