import { Logger } from './../logger.service';
import { Injectable } from '@angular/core';
import { CredentialsService } from './credentials.service';

const logger = new Logger('TokenService');

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private _tokenActive = false;

  constructor(private credentialsService: CredentialsService) {}

  getToken() {
    const credentials = this.credentialsService.credentials;

    return credentials ? credentials.access_token : null;
  }

  isTokenExpired() {
    return false;
  }

  isTokenActive(): boolean {
    const token = this.getToken();

    if (token) {
      this._tokenActive = !this.isTokenExpired();
    }

    return this._tokenActive;
  }

  tokenRequired() {
    logger.debug('Token is expired');
    return false;
  }
}
