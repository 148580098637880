import { env } from './.env';

export const environment = {
  production: (env.IS_SERVER == 'true'),
  hmr: false,
  version: env.npm_package_version + '-dev',
  baseUrl: env.API_URL,
  serverUrl: env.API_URL + '/customers',
  defaultLanguage: 'it-IT',
  supportedLanguages: ['it-IT'],
};
