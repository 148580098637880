import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { LayoutService, Layouts } from '@core';
import { AccountCallsService } from '@shared/services/account.calls.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import * as FileSaver from 'file-saver';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'sw-main-body',
  templateUrl: './main-body.component.html',
  styleUrls: ['./main-body.component.scss'],
  host: { class: 'main-body' },
})
export class MainBodyComponent implements OnInit {
  @HostBinding('class.horizontal-default')
  get isHDefault(): boolean {
    return this._layout.layoutToUse === Layouts.HorizontalDefault;
  }

  @HostBinding('class.vertical-default')
  get isVDefault(): boolean {
    return this._layout.layoutToUse === Layouts.VerticalDefault;
  }

  public cgcDocument: {
    document_base64: string,
    agency: AppAgency,
    id: number
  };

  public submittedCgc: boolean = false;

  @ViewChild('cgcModal') private cgcModal: any;

  private openedModal: any;
  private loop: Subscription;

  constructor(private _layout: LayoutService, private api: AccountCallsService, private ngbModal: NgbModal) {}

  public openCgcModal() {
    this.openedModal = this.ngbModal.open(this.cgcModal, {
      size: 'xl',
      backdrop: false,
      beforeDismiss: () => {
        return false;
      },
    });
  }

  public submitCgc(approved: boolean) {
    this.submittedCgc = true;

    this.api.submitCgc(approved, this.cgcDocument.id).subscribe(
      () => {
        this.openedModal.close();
        this.loadCgc();
        this.submittedCgc = false;
      },
      () => {
        this.submittedCgc = false;
      }
    );
  }

  ngOnInit() {
    this.loadCgc();

    this.loop = interval(15 * 60 * 1000).subscribe((_: any) => {
      this.loadCgc();
    });
  }

  public loadCgc() {
    this.api.lastCgc().subscribe((data) => {
      if (data && !data.done) {
        this.cgcDocument = data;
        this.openCgcModal();
      }
    });
  }

  public downloadCgc() {
    this.downloadFile(this.cgcDocument.document_base64, 'Condizioni Generali di Contratto '+this.cgcDocument.agency.name+'.pdf');
  }

  public downloadFile(b64Data: string, filename: string) {
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    FileSaver.saveAs(url, filename);
  }
}
