<form (ngSubmit)="onSubmit()" [formGroup]="form" class="cozy" novalidate>
    <div class="form-group">
        <label class="control-label bold small text-uppercase">{{ 'AUTH.USERNAME' | translate }}</label>
        <input type="email" formControlName="email" class="form-control display-errors bg-light form-control-rounded" [placeholder]="'AUTH.USERNAME' | translate" />
        <small [hidden]="f.email.valid || f.email.untouched" class="text-danger">
            {{ 'AUTH.USERNAME' | translate }} {{ 'IS_REQUIRED' | translate }}
        </small>
    </div>

    <div class="form-group">
        <label class="control-label bold small text-uppercase">{{ 'AUTH.CUSTOMER_CODE' | translate }}</label>
        <input formControlName="customer_code" class="form-control display-errors bg-light form-control-rounded" [placeholder]="'AUTH.CUSTOMER_CODE_PLACEHOLDER' | translate" />
        <small *ngIf="f.customer_code.errors && f.customer_code.touched" class="text-danger">
            {{ f.customer_code.errors.translated_error }}
        </small>
    </div>

    <div class="form-group mb-0 d-flex align-items-center justify-content-between">
        <a routerLink="/login" class="text-warning small">{{ 'AUTH.LOGIN' | translate }}</a>

        <sw-button type="submit" color="primary" [icon]="icons.faLongArrowAltRight" [isLoading]="isLoading">
            <span>{{ 'AUTH.RECOVER_PASSWORD' | translate }}</span>
        </sw-button>
    </div>
</form>