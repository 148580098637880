<sw-sidenav [class.mobile-hidden]="mobileCollapsed" [collapsed]="sidenavCollapsed" [fixed]="fixedSidenav" (sideNavToggled)="onSidenavToggled($event)"></sw-sidenav>

<sw-main-body [ngClass]="{
    'sidenav-collapsed': sidenavCollapsed,
    'quick-sidenav-expanded': !isQuickSidenavCollapsed
  }">
    <sw-header [fixed]="fixedHeader">
        <sw-header-toolbar [sidenavCollapsed]="sidenavCollapsed" [quickSidenavCollapsed]="isQuickSidenavCollapsed" (sidenavToggled)="onSidenavToggled($event)" (quickSidenavToggled)="onQuickSidenavToggled($event)"></sw-header-toolbar>
    </sw-header>

    <main class="body-wrapper">
        <router-outlet></router-outlet>
    </main>

    <sw-footer></sw-footer>
</sw-main-body>

<sw-quick-sidenav [collapsed]="isQuickSidenavCollapsed"></sw-quick-sidenav>

<sw-page-overlay *ngIf="!isQuickSidenavCollapsed" class="quick-sidenav-overlay">
    <fa-icon [icon]="closeIcon" class="close" (click)="onQuickSidenavToggled(true)"></fa-icon>
</sw-page-overlay>