import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sw-sidenav-link',
  templateUrl: './sidenav-link.component.html',
  styleUrls: ['./sidenav-link.component.scss'],
})
export class SidenavLinkComponent implements OnInit {
  /**
   * TODO: Implement this component to avoid duplication of styling rules
   * in sidenav and sidenav-item
   */
  constructor() {}

  ngOnInit() {}
}
