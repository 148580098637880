import { Account } from '@core/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class AccountCallsService {
  constructor(private api: ApiService) {}

  getAccount = function (): Observable<Account> {
    return this.api.get('account');
  };

  updateAccount = function (account: Account): Observable<Account> {
    return this.api.post('account', account);
  };

  lastCgc = function (): Observable<any> {
    return this.api.get('documents/approved_cgc');
  };
  submitCgc = function (approved: boolean, id: number): Observable<boolean> {
    return this.api.post('documents/approve_cgc', { approved, id });
  };

  getCustomerData = function (): Observable<any> {
    return this.api.get('customers/data');
  };
  /*updateEmail = function(data: any): Observable<{
    email: string;
    username: string;
    verification_sent: boolean;
  }> {
    return this.api.post('account/email', data);
  };

  updatePassword = function(data: any): Observable<any> {
    return this.api.post('account/password', data);
  };*/
}
