import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/i18n';
import { AnnouncementsComponent } from '@app/content/announcements/announcements.component';

const routes: Routes = [
  { path: '', component: AnnouncementsComponent, data: { title: extract('ANNOUNCEMENTS.TITLE') } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnnouncementsRoutingModule {}
