<div class="modal-header">
  <h5 class="modal-title" style="width: 100%">
    <span>{{ announcement.subject }}</span>
    <span style="float: right"
      ><sw-icon [icon]="icons.faCalendar"></sw-icon> {{ announcement.created_at | date: 'dd/MM/yyyy HH:mm' }}</span
    >
  </h5>
  <button type="button" class="close" (click)="dismissModal()">
    <sw-icon icon="x"></sw-icon>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 mt-4 mb-4">
      <div [innerHTML]="announcement.content | unsanitize"></div>
    </div>

    <div class="col-12 mt-4 mb-4">
      <ng-container *ngIf="announcement.attachments.length > 0">
        <ngx-datatable
          class="ngx-datatable responsive table table-hover"
          [rows]="announcement.attachments"
          [columnMode]="ColumnMode.flex"
          headerHeight="auto"
          [rowHeight]="'auto'"
          footerHeight="auto"
          [limit]="30"
          [scrollbarH]="true"
          #table
        >
          <ngx-datatable-column [name]="'ANNOUNCEMENTS.ATTACHMENTS.NAME' | translate" prop="file_name" [flexGrow]="1">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{ value }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="'ANNOUNCEMENTS.ATTACHMENTS.CREATED_AT' | translate"
            prop="created_at"
            [flexGrow]="3"
          >
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{ value | date: 'dd/MM/yyyy HH:mm' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [name]="'ANNOUNCEMENTS.ACTIONS' | translate" prop="" [flexGrow]="1">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <sw-button
                type="button"
                (click)="downloadFile(value)"
                color="success"
                [icon]="icons.faFileDownload"
                [isLoading]="isLoading"
              >
                {{ 'ANNOUNCEMENTS.ATTACHMENTS.DOWNLOAD' | translate }}
              </sw-button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </ng-container>
      <ng-template #loading>
        <sw-loader [template]="'table'" [isLoading]="isLoading" [count]="3"></sw-loader>
      </ng-template>
    </div>
  </div>
</div>
<div class="modal-footer">
  <sw-button type="button" [color]="'secondary'" (click)="dismissModal()">
    {{ 'CLOSE' | translate }}
  </sw-button>
</div>
