import { Directive, HostListener, ElementRef, Renderer2, Output, EventEmitter, OnInit, Input } from '@angular/core';

import { Logger } from '@core';
const logger = new Logger('OpenParentDirective');

@Directive({
  // tslint:disable-next-line
  selector: '[openParent]',
})
export class OpenParentDirective {
  isOpen: boolean = false;

  // tslint:disable-next-line
  @Output()
  onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _elementRef: ElementRef<HTMLElement>, private _renderer: Renderer2) {}

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    e.stopPropagation();
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this._renderer.addClass(this._elementRef.nativeElement.parentNode, 'open');
    } else {
      this._renderer.removeClass(this._elementRef.nativeElement.parentNode, 'open');
    }

    this.onToggle.emit(this.isOpen);
  }
}
