<form (ngSubmit)="onSubmit()" [formGroup]="form" class="cozy" novalidate>
    <div class="form-group">
        <label class="control-label bold small text-uppercase">{{ 'AUTH.NEW_PASSWORD' | translate }}</label>
        <input type="password" class="form-control form-control-rounded display-errors" formControlName="password" autocomplete="new-password" placeholder="{{ 'AUTH.NEW_PASSWORD' | translate }}" />
        <small class="text-danger" *ngIf="f.password.errors?.required && submitted">
      {{ 'AUTH.VALIDATION.RESET_PASSWORD_REQUIRED' | translate }}
    </small>
        <small class="text-danger" *ngIf="f.password.errors?.minlength && submitted">
      {{ 'ACCOUNT.VALIDATION.PASSWORD_MIN_LENGTH' | translate }}
    </small>
    </div>
    <div class="form-group">
        <label class="control-label bold small text-uppercase">{{ 'ACCOUNT.PASSWORD_CONFIRMATION' | translate }}</label>
        <input type="password" class="form-control form-control-rounded display-errors" formControlName="password_confirmation" placeholder="{{ 'ACCOUNT.PASSWORD_CONFIRMATION' | translate }}" [ngClass]="{ 'ng-invalid': form.errors?.password_not_same }" />
        <small class="text-danger" *ngIf="form.errors?.password_not_same && submitted">
      {{ 'ACCOUNT.VALIDATION.PASSWORD_NOT_SAME' | translate }}
    </small>
    </div>

    <div class="form-group mb-0 d-flex align-items-center justify-content-between">
        <a routerLink="/login" class="text-warning small">{{ 'AUTH.LOGIN' | translate }}</a>

        <sw-button type="submit" color="primary" [icon]="icons.faLongArrowAltRight" [isLoading]="isLoading">
            <span>{{ 'AUTH.UPDATE_PASSWORD' | translate }}</span>
        </sw-button>
    </div>
</form>