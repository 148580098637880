import { Logger } from './../logger.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { AuthorizationEntity } from './authentication.models';

const credentialsKey = 'credentials';

const logger = new Logger('CredentialsService');

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: AuthorizationEntity | null = null;

  constructor(private cookieService: CookieService) {
    const savedCredentials = this.cookieService.get(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  get credentials(): AuthorizationEntity | null {
    return this._credentials;
  }

  setCredentials(authorizationEntity?: AuthorizationEntity, remember?: boolean) {
    logger.debug('set credentials called', { authorizationEntity, remember });
    this._credentials = authorizationEntity || null;

    if (authorizationEntity) {
      const storage = remember ? this.cookieService : localStorage;

      if (remember) {
        this.cookieService.set(credentialsKey, JSON.stringify(authorizationEntity), 7, '/');
      } else {
        localStorage.setItem(credentialsKey, JSON.stringify(authorizationEntity));
      }
    } else {
      this.cookieService.delete(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  public clearCredentials() {
    this.setCredentials();
  }

  public can(permission: string) {
    if (this.credentials.user.is_admin) {
      return true;
    }
    if (
      this.credentials.user.permissions.filter((prm) => {
        return prm.name === permission;
      }).length > 0
    ) {
      return true;
    }
    return false;
  }
}
