<div class="sidenav-logo">
    <a class="sidenav-brand" href="javascript:;">
        <img src="assets/img/logo.svg" alt="Portale Clienti" class="logo" />
    </a>

    <sw-menu-toggler class="sidenav-toggler" dark="true" (click)="toggleSidenav()"></sw-menu-toggler>
</div>

<div perfectScrollbar class="sidenav-content">
    <nav class="nav flex-column">
        <!-- USER -->
        <div class="nav-item user-sidenav nav-dropdown">
            <a href="javascript:;" class="nav-link" openParent (onToggle)="user.open = $event">
                <sw-avatar-info [name]="user.avatar.name" [info]="user.info">
                    <sw-avatar [avatar]="user.avatar" class="user-picture"></sw-avatar>
                </sw-avatar-info>

                <sw-dropdown-icon [open]="user.open" class="dd-icon"></sw-dropdown-icon>
            </a>

            <div class="nav-dropdown-items">
                <nav class="nav justify-content-between">
                    <a href="javascript:;" class="nav-link text-truncate m-0" (click)="logout()">
                        <fa-icon [icon]="icons.faSignOutAlt" class="mr-1"></fa-icon>
                        <span class="small">Logout</span>
                    </a>
                </nav>
            </div>
        </div>

        <!-- ALL NAVIGATION OPTIONS -->
        <ng-container *ngFor="let group of navGroups">
            <div class="nav-header">
                <span>{{ group.title }}</span>
            </div>

            <sw-sidenav-item *ngFor="let option of group.items" [option]="option" [collapsed]="collapsed" [hover]="hover"></sw-sidenav-item>
        </ng-container>
    </nav>
</div>
