<sw-card [class.disabled]="isDisabled" [class.overflow-hidden]="!isOpen">
    <sw-card-header class="accordion-group-header" (click)="toggleOpen()" [border]="false">
        <div class="accordion-heading-content">
            <a href="javascript:;" class="card-title">
                <span *ngIf="heading">{{ heading }}</span>
                <ng-content select="[accordion-heading]"></ng-content>
            </a>
        </div>

        <sw-dropdown-icon class="ml-auto" [open]="isOpen"></sw-dropdown-icon>
    </sw-card-header>

    <div class="accordion-group-content" [collapse]="!isOpen" [isAnimated]="isAnimated">
        <sw-card-body>
            <ng-content></ng-content>
        </sw-card-body>
    </div>
</sw-card>