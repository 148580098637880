import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  faCar,
  faTruck,
  faTabletAlt,
  faFileInvoice,
  faReceipt,
  faArchive,
  faLink,
  faBookmark,
  faList,
  faArchway,
  faUser,
  faWrench,
  faHome,
  faBell,
  faUsers,
  faCalendarCheck,
} from '@fortawesome/free-solid-svg-icons';
import { NavigationOptions } from '@app/layout';
import { CredentialsService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private translate: TranslateService, private credentials: CredentialsService) {}

  getNavigationItems(): Observable<NavigationOptions[]> {
    const navigationItems: any = [
      {
        icon: { name: faHome },
        title: this.translate.instant('HOME.TITLE'),
        link: '/home',
      },
    ];

    if (
      this.credentials.can('user_customers.permissions_management') ||
      this.credentials.can('user_customers.anagrafiche')
    ) {
      const items = [];
      if (this.credentials.can('user_customers.anagrafiche')) {
        items.push({
          icon: {},
          title: this.translate.instant('ACCOUNT.CUSTOMER_DATA'),
          link: '/account/customer_data',
        });
      }
      if (this.credentials.can('user_customers.permissions_management')) {
        items.push({
          icon: {},
          title: this.translate.instant('USERS.MENU_TITLE'),
          link: '/utenti',
        });
      }
      navigationItems.push({
        icon: { name: faUsers },
        title: this.translate.instant('USERS.MENU_MASTER_TITLE'),
        items,
      });
    }

    if (this.credentials.can('user_customers.transiti')) {
      navigationItems.push({
        icon: { name: faCar },
        title: this.translate.instant('TRANSITS.TITLE'),
        link: '/transits',
      });
    }

    if (this.credentials.can('user_customers.trafori')) {
      navigationItems.push({
        title: this.translate.instant('PRENOTAZIONI.TITLE'),
        icon: { name: faBookmark },
        items: [
          {
            icon: { name: faArchway },
            title: this.translate.instant('TRAFORI.TITLE'),
            link: '/trafori',
          },
        ],
      });
    }

    if (this.credentials.can('user_customers.anagrafiche')) {
      navigationItems.push({
        icon: { name: faList },
        title: this.translate.instant('MENU.ANAGRAFICHE'),
        items: [
          {
            icon: { name: faTruck },
            title: this.translate.instant('VEHICLES.TITLE'),
            link: '/vehicles',
          },
          {
            icon: { name: faTabletAlt },
            title: this.translate.instant('DEVICES.TITLE'),
            link: '/devices',
          },
        ],
      });
    }

    if (this.credentials.can('user_customers.credentials')) {
      const items = [
        /*{
          title: this.translate.instant('USEFUL_LINKS.TITLE'),
          icon: { name: faLink },
          link: '/useful-links',
        },
        {
          title: this.translate.instant('PLANNING.TITLE'),
          icon: { name: faCalendarCheck },
          link: '/planning',
        },*/
      ];

      if (this.credentials.can('user_customers.credentials')) {
        items.push({
          title: this.translate.instant('LOGIN_CREDENTIALS.TITLE'),
          icon: { name: faUser },
          link: '/login-credentials',
        });
      }

      if (this.credentials.can('user_customers.documents')) {
        items.push({
          title: this.translate.instant('DOCUMENTS.TITLE'),
          icon: { name: faArchive },
          link: '/documents',
        });
      }

      navigationItems.push({
        icon: { name: faWrench },
        title: this.translate.instant('MENU.UTILITY'),
        items,
      });
    }

    if (this.credentials.can('user_customers.invoices')) {
      navigationItems.push({
        icon: { name: faFileInvoice },
        title: this.translate.instant('INVOICES.TITLE'),
        items: [
          {
            icon: {},
            title: this.translate.instant('INVOICES.TITLE'),
            link: '/invoices',
          },
          {
            icon: {},
            title: this.translate.instant('TELEPASS_INVOICES.TITLE'),
            link: '/telepass_invoices',
          },
        ],
      });
    }

    if (this.credentials.can('user_customers.rimborsi')) {
      navigationItems.push({
        icon: { name: faReceipt },
        title: this.translate.instant('REFUND_REQUESTS.MENU_TITLE'),
        link: '/refunds',
      });
    }

    navigationItems.push({
      icon: { name: faBell },
      title: this.translate.instant('ANNOUNCEMENTS.TITLE'),
      link: '/announcements',
    });

    return of(navigationItems);
  }
}
