import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule, ApplicationConfigurationService } from '@core';
import { SharedModule } from '@shared';
import { ShellModule } from './shell/shell.module';
import { AuthModule } from './content/auth/auth.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';

import { getBsDatepickerConfiguration, getBsModulesForRoot } from './bootstrap/bootstrap.module';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { UtilsModule } from './blocks/utils';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

// the locale 'it' needs to be imported and registered to use the locale data
import localeIt from '@angular/common/locales/it';
import { AnnouncementsModule } from '@app/content/announcements/announcements.module';
registerLocaleData(localeIt);

/**
 * Perfect Scrollbar Default Configuration
 */
const defaultPerfectScrollbarConfiguration: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

/**
 * Initializing Application
 * Here we load the configuration for the layout and some other stuffs that
 * should be triggered once when the application is loading
 */
const initializeApp = (_config: ApplicationConfigurationService) => {
  return () => _config.initialize();
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),

    CoreModule,
    ShellModule,
    SharedModule,
    UtilsModule,
    AuthModule,
    AnnouncementsModule,

    PerfectScrollbarModule,

    // Ngx Bootstrap
    ...getBsModulesForRoot(),

    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    CommonModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),

    TypeaheadModule.forRoot(),
  ],
  declarations: [AppComponent],
  providers: [
    // App Initializer
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ApplicationConfigurationService],
      multi: true,
    },

    // provide the LOCALE_ID for formatting dates, numbers,...
    { provide: LOCALE_ID, useValue: 'it-IT' },

    // PerfectScrollbar Configuration
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: defaultPerfectScrollbarConfiguration,
    },

    // Ngx-Bootstrap Datepicker Default Configuration
    {
      provide: BsDatepickerConfig,
      useFactory: getBsDatepickerConfiguration,
    },

    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
