import { Logger } from './../logger.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Directive, ElementRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from './base-component';

const logger = new Logger('BaseFormComponent');

@Component({
  selector: 'app-base',
  template: `<div>base works!!</div>`,
})
export class BaseFormComponent extends BaseComponent {
  public error: string;
  public form: FormGroup;
  public submitted = false;

  public get f() {
    return this.form.controls;
  }

  public get formValue() {
    return this.form.value;
  }

  public constructor(public el: ElementRef, public toastr: ToastrService, public translate: TranslateService) {
    super();
  }

  protected beforeSubmit() {
    this.form.markAllAsTouched();
    this.submitted = true;

    if (!this.form.valid) {
      this.scrollToFirstInvalidControl();
      this.toastr.error(this.translate.instant('FORMS.CORRECT_THE_ERRORS'), this.translate.instant('FORMS.INVALID'));
      return false;
    }
    this.isLoading = true;
    return true;
  }

  protected finalizeSubmit() {
    this.form.markAsPristine();
    this.isLoading = false;
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('input.ng-invalid');
    const firstInvalid: HTMLElement = this.el.nativeElement.querySelector('.ng-invalid');

    logger.debug({ firstInvalidControl, firstInvalid });

    if (firstInvalidControl) {
      logger.debug('ok focusso');
      firstInvalidControl.focus();
    } else if (firstInvalid) {
      logger.debug('ok scrollo');
      firstInvalid.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
