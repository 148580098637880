import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseFormComponent } from '@app/@core';
import { faFileCsv, faPencilAlt, faPlus, faSave, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnnouncementCallsService } from '@shared/services/announcement.calls.service';
import { Announcement } from '@core/api-models/announcements/announcement.model';

@Component({
  selector: 'sw-announcements',
  templateUrl: './announcements.component.html',
})
export class AnnouncementsComponent extends BaseFormComponent implements OnInit {
  public icons = {
    faSave,
    faFileCsv,
    faSearch,
    faPlus,
    faPencilAlt,
    faTrash,
  };

  public rows: Announcement[];
  public ColumnMode = ColumnMode;

  public selectedAnnouncement: Announcement = null;
  @ViewChild('announcementModal') private announcementModalTemplate: TemplateRef<any>;

  constructor(
    private api: AnnouncementCallsService,
    private fb: FormBuilder,
    public el: ElementRef,
    public toastr: ToastrService,
    public translate: TranslateService,
    private ngbModal: NgbModal
  ) {
    super(el, toastr, translate);
  }

  ngOnInit(): void {
    this.buildForm();
    this.onSubmit();
  }

  /**
   * form was submitted
   */
  onSubmit() {
    const { value } = this.form;
    if (!this.beforeSubmit()) return;

    this.api
      .getAnnouncementsTable(value)
      .pipe(finalize(this.finalizeSubmit.bind(this)))
      .subscribe((data) => {
        this.rows = data;
      });
  }

  /**
   * build the form
   */
  buildForm() {
    const date = moment();

    this.form = this.fb.group({
      subject: [null],
      date_from: [date.clone().subtract(7, 'day').format('YYYY-MM-DD')],
      date_to: [date.clone().format('YYYY-MM-DD')],
    });
  }

  openAnnouncementModal(announcement: Announcement) {
    this.selectedAnnouncement = announcement;
    const modalRes = this.ngbModal.open(this.announcementModalTemplate, { size: 'xl' });
    modalRes.result.then(() => {}).catch((_) => {});
  }
}
