import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthRegisterComponent } from './components/auth-register/auth-register.component';

@NgModule({
  declarations: [AuthRoutingModule.declarations, AuthRegisterComponent],
  imports: [SharedModule, AuthRoutingModule],
})
export class AuthModule {}
