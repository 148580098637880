import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/@shared';
import { UtilsModule } from '@app/blocks/utils';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AnnouncementModalComponent } from '@app/content/announcements/modal/announcement.modal.component';
import { AnnouncementsRoutingModule } from '@app/content/announcements/announcements.routing.module';
import { AnnouncementsComponent } from '@app/content/announcements/announcements.component';

@NgModule({
  declarations: [AnnouncementModalComponent, AnnouncementsComponent],
  imports: [UtilsModule, AnnouncementsRoutingModule, NgxDatatableModule, CommonModule, SharedModule],
  exports: [AnnouncementModalComponent, AnnouncementsComponent],
})
export class AnnouncementsModule {}
