<sw-page-header [title]="'ANNOUNCEMENTS.TITLE' | translate"> </sw-page-header>

<ng-template #announcementModal let-modal>
  <sw-announcement-modal [modal]="modal" [announcement]="selectedAnnouncement"> </sw-announcement-modal>
</ng-template>

<div class="container-fluid">
  <sw-card>
    <sw-card-header>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="row">
        <div class="col-4">
          <label class="form-label small text-uppercase bold">{{ 'ANNOUNCEMENTS.SUBJECT' | translate }}</label>
          <input
            class="form-control display-errors"
            formControlName="subject"
            type="text"
            [placeholder]="'ANNOUNCEMENTS.SUBJECT' | translate"
          />
        </div>
        <div class="col-3">
          <label class="form-label small text-uppercase bold">{{ 'ANNOUNCEMENTS.DATE_FROM' | translate }}</label>
          <input
            class="form-control display-errors"
            formControlName="date_from"
            type="date"
            min="1900-01-01"
            max="2199-12-31"
            [placeholder]="'ANNOUNCEMENTS.DATE_FROM' | translate"
          />
        </div>
        <div class="col-3">
          <label class="form-label small text-uppercase bold">{{ 'ANNOUNCEMENTS.DATE_TO' | translate }}</label>
          <input
            class="form-control display-errors"
            formControlName="date_to"
            type="date"
            min="1900-01-01"
            max="2199-12-31"
            [placeholder]="'ANNOUNCEMENTS.DATE_TO' | translate"
          />
        </div>
        <div class="col-2 mt-4">
          <sw-button type="submit" [icon]="icons.faSearch" [isLoading]="isLoading">
            {{ 'ANNOUNCEMENTS.SEARCH' | translate }}
          </sw-button>
        </div>
      </form>
    </sw-card-header>
    <sw-card-body>
      <ng-container *ngIf="rows; else loading">
        <ngx-datatable
          class="ngx-datatable responsive table table-hover"
          [rows]="rows"
          [columnMode]="ColumnMode.force"
          headerHeight="auto"
          [rowHeight]="'auto'"
          footerHeight="auto"
          [limit]="30"
          [scrollbarH]="true"
          #table
        >
          <ngx-datatable-column [name]="'ANNOUNCEMENTS.DATE' | translate" prop="created_at">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{ value | date : 'dd/MM/yyyy HH:mm' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [name]="'ANNOUNCEMENTS.SUBJECT' | translate" prop="subject">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <a class="pointer" (click)="openAnnouncementModal(row)">
                <u
                  ><b>{{ value }}</b></u
                >
              </a>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'ANNOUNCEMENTS.FILES' | translate" prop="attachments">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span *ngIf="value.length > 0">{{ value.length }}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </ng-container>
      <ng-template #loading>
        <sw-loader [template]="'table'" [isLoading]="isLoading" [count]="3"></sw-loader>
      </ng-template>
    </sw-card-body>
  </sw-card>
</div>

<ng-template #loading>
  <sw-loader [template]="'table'" [isLoading]="isLoading" [count]="3"></sw-loader>
</ng-template>
