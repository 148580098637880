<sw-main-body
  [ngClass]="{
    'quick-sidenav-expanded': !isQuickSidenavCollapsed
  }"
>
  <sw-header [fixed]="fixedHeader">
    <sw-header-toolbar
      branded="true"
      dark="true"
      [quickSidenavCollapsed]="isQuickSidenavCollapsed"
      (quickSidenavToggled)="onQuickSidenavToggled($event)"
      [sidenavCollapsed]="isSidenavCollapsed"
      (sidenavToggled)="onSidenavToggled($event)"
    ></sw-header-toolbar>

    <sw-header-navbar
      [sidenavCollapsed]="isSidenavCollapsed"
      (sideNavToggled)="onSidenavToggled($event)"
    ></sw-header-navbar>
  </sw-header>

  <main class="body-wrapper overflow-hidden" style="background-color: #f0f3f6">
    <router-outlet></router-outlet>
  </main>

  <sw-footer></sw-footer>
</sw-main-body>

<sw-quick-sidenav [collapsed]="isQuickSidenavCollapsed"></sw-quick-sidenav>

<sw-page-overlay *ngIf="!isQuickSidenavCollapsed" class="quick-sidenav-overlay">
  <fa-icon [icon]="closeIcon" class="close" (click)="onQuickSidenavToggled(true)"></fa-icon>
</sw-page-overlay>
